import { toast } from "react-hot-toast";

function postCrossDomainMessage(token, data) {
  if (process.env.REACT_APP_ENV === "local") {
    document.cookie = `${process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_TOKEN_KEY}=${token};`;
    document.cookie = `${
      process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_USER_DATA
    }=${JSON.stringify(data)} ; `;
  } else {
    document.cookie = `${process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_TOKEN_KEY}=${token}; domain=tripmerchant.ca; SameSite=Strict; secure`;
    document.cookie = `${
      process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_USER_DATA
    }=${JSON.stringify(data)}; domain=tripmerchant.ca; SameSite=Strict; secure`;
  }
  /*  window.location.replace(
    process.env.REACT_APP_ENV === "local"
      ? "http://localhost:3001"
      : process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_URL
  ); */
}

export default postCrossDomainMessage;

export const RemoveCookie = () => {
  document.cookie =
    process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_TOKEN_KEY +
    "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  document.cookie =
    process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_USER_DATA +
    "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  //document.clearCookie();
};

export const decodeCookie = (cname) => {
  const name = `${cname}`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const DomainRedirection = (token, domain) => {
  let url =
    process.env.REACT_APP_ENV === "local"
      ? domain !== null &&
        domain !== undefined &&
        domain !== "members.tripmerchant.com"
        ? `http://${domain}.localhost:3001`
        : `http://localhost:3001`
      : domain !== null &&
        domain !== undefined &&
        domain.includes("tripmerchant.com")
      ? `https://${domain}`
      : `https://${domain}.tripmerchant.com`;
  console.log(url);
  /* if (url !== "" || url !== null || url !== undefined) */
  window.location.replace(`${url}/verify?token=${token}`);
};

export const GetLogInInfo = () => {
  if (
    process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_USER_DATA !==
      "undefined" &&
    process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_USER_DATA !== "undefined"
  ) {
    let token = decodeCookie(
      process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_TOKEN_KEY
    ).slice(1);
    let userData = decodeCookie(
      process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_USER_DATA
    )
      ? JSON?.parse(
          decodeCookie(
            process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_USER_DATA
          ).slice(1)
        )
      : "";
    if (token !== "" && userData !== "") {
      DomainRedirection(token, userData?.domain);
      toast.dismiss();
      toast.success("Already LoggedIn");
    } else {
      RemoveCookie();
    }
  } else {
    RemoveCookie();
  }
};

export const redirectToOrganizationDomain = (response) => {
  console.log(response);
  let token = response?.meta?.token;
  let domain = response?.data?.organization?.domain;
  console.log(domain);
  document.cookie = `${process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_TOKEN_KEY}=${response?.meta?.token};`;
  document.cookie = `${
    process.env.REACT_APP_TRIP_MERCHANT_ORGANIZATION_USER_DATA
  }=${JSON.stringify(response?.data)} ; `;
  DomainRedirection(token, domain);
};
