import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { HeroSection } from "../../components/HeroSection";
import { getData, strapiUrl } from "../../common/strapiUrl";
import ReactMarkdown from "react-markdown";

const policy = `<p>This Terms of Service Agreement (this &ldquo;<strong>Agreement</strong>&ldquo;) governs your access to and use of the websites, services and applications (collectively, the &ldquo;<strong>Site</strong>&ldquo;) which are owned, operated or provided by Trip Merchant Ltd. (&ldquo;<strong>Trip Merchant</strong>&ldquo;). &nbsp;In consideration for your right to access and use the Site, you agree to the terms and conditions set out below.</p>
<p>&nbsp;</p>
<p>As used in this Agreement, &ldquo;<strong>you</strong>&rdquo; means (and &ldquo;<strong>your</strong>&rdquo; refers to) the user of the Site, &ldquo;<strong>we</strong>&rdquo; means (and &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;, and &ldquo;<strong>ours</strong>&rdquo; refer to) Trip Merchant, and &ldquo;<strong>Services</strong>&rdquo; means any and all services and products that are made available on or through the Site. &nbsp;The effective date of this Agreement is when you accept it, in accordance with the terms and conditions that are set out below.</p>
<p>&nbsp;</p>
<p>IMPORTANT! YOUR ACCESS TO THE SITE IS SUBJECT TO LEGALLY BINDING TERMS AND CONDITIONS. &nbsp;CAREFULLY READ ALL OF THE FOLLOWING TERMS AND CONDITIONS BEFORE YOU PROCEED. &nbsp;ACCESSING THIS SITE IS THE EQUIVALENT OF YOUR SIGNATURE AND INDICATES YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS AND THAT YOU INTEND TO BE LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, PLEASE DO NOT USE THE SITE.</p>
<p>&nbsp;</p>
<p><strong>Part 1 &ndash; Suppliers</strong></p>
<p>&nbsp;</p>
<p>Unless otherwise indicated, Part 1 applies only to a Supplier and Suppliers (as those terms are described below in this Part). &nbsp;As used in this Part, &ldquo;<strong>you</strong>&rdquo; means (and &ldquo;<strong>your</strong>&rdquo; refers to) a Supplier or Suppliers, as the case may be, together with &ldquo;Authorized Users&rdquo; (as that term is defined below in this Part) of a Supplier or Suppliers, and &ldquo;<strong>we</strong>&rdquo; means (and &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;, and &ldquo;<strong>ours</strong>&rdquo; refer to) Trip Merchant.</p>
<p>&nbsp;</p>
<ol>
    <li><strong>Our Services</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Site provides a digital marketing platform for membership-based organizations and employers (&ldquo;<strong>Membership-Based Organizations and Employers</strong>&ldquo;) to connect their employees and/or members (&ldquo;<strong>Customers</strong>&ldquo;) with organizations who offer travel experiences (&ldquo;<strong>Suppliers</strong>&ldquo;), for the purposes of allowing Customers to request products and services from Suppliers, and to allow Suppliers to respond to those requests. &nbsp;We give Customers the ability to customize their profile and to submit Requests (as that term is defined below) for information about travel experiences to Suppliers. &nbsp;Here&rsquo;s how it works:</p>
<ul>
    <li>Membership-Based Organizations and Employers partner with us, by entering into a written agreement (a &ldquo;<strong>Specific Agreement</strong>&ldquo;), which Specific Agreements shall set out the specific scope of the Services to be offered by Trip Merchant, to the particular Membership-Based Organization and Employer. As part of that process, Membership-Based Organizations and Employers agree to partner with us to promote the Services to the Customers who are affiliated with their organizations, thereby encouraging Customers to sign up for e-newsletters, email and other electronic messages, about travel experience opportunities which may be offered by Suppliers (such as adventure travel, ocean/river cruises, escorted guided tours, etc.). &nbsp;Customers who wish to use the Site must also sign up to receive a username and password;</li>
    <li>Trip Merchant also enters into relationships with select Suppliers, with whom we partner to offer services through the Site. While Suppliers primarily offer travel experience opportunities, they may also offer information and services, including, but not limited to, services related to vacation home purchasing and rental opportunities;</li>
    <li>we create individualized webpages for our Membership-Based Organizations and Employers (&ldquo;<strong>Membership-Based Organization and Employer Websites</strong>&ldquo;), which serve as a dashboard for information about travel experiences that are provided by Suppliers. Access to the Membership-Based Organization and Employer Websites is limited to Customers who are affiliated with that Membership-Based Organization and Employer, and who have signed up to receive a username and password with the Site, as well as the friends and family of those Customers;</li>
    <li>we work with our Suppliers to obtain membership savings and other opportunities which are customized for each Membership-Based Organization and Employer&rsquo;s Customers and then are made available on Membership-Based Organization and Employer Websites;</li>
    <li>the Site allows Customers direct access to Suppliers, for the purposes of obtaining information about either specific travel experiences or products and services offered by the Supplier more generally (&ldquo;<strong>Requests</strong>&ldquo;);</li>
    <li>Requests are provided to individual sales representatives of the Suppliers (who, along with the Supplier&rsquo;s account administrator, are collectively referred to as the Supplier&rsquo;s &ldquo;<strong>Authorized Users</strong>&ldquo;), who are then able to contact the Customer directly to discuss the Request further and to make arrangements regarding the provision to the Customer of travel experiences and other products or services that might be offered by the Supplier;</li>
    <li>in addition to the Request process, the Site may offer analytics to Membership-Based Organizations and Employers and Suppliers, including purchasing patterns, trends, past and current Requests and feedback provided by Customers about their travel experiences and other products and services which have been provided by Suppliers (&ldquo;<strong>Testimonials and Member Experiences</strong>&ldquo;); and</li>
    <li>the Site also provides a directory of information about Suppliers, by making each Supplier&rsquo;s submitted names and phone numbers available to Customers.</li>
</ul>
<p>The services offered through the Site which are described in this&nbsp;<strong>Section 1&nbsp;</strong>are referred to in this Agreement as the &ldquo;<strong>Services</strong>&ldquo;. &nbsp;Notwithstanding the foregoing, the above described Services are not meant to represent a comprehensive list of the available Services and this Agreement shall apply to and govern all Services presently available or that are hereafter offered from time to time by us, on or through the Site. &nbsp;The Services may be offered in such geographic regions as we may make available, from time to time, and we reserve the right to expand or reduce such regions, for any reason and without notice to you.</p>
<p>&nbsp;</p>
<ol start="2">
    <li><strong>Payment</strong></li>
</ol>
<p>&nbsp;</p>
<p><strong>Terms of Payment</strong>: &nbsp;Terms of payment are as set out on the Site, from time to time.</p>
<p><strong>Price</strong>: &nbsp;Suppliers pay for the Services in two ways. &nbsp;First, Suppliers are levied an annual marketing investment fee, which is initially due as part of the onboarding process and is subject to the terms and conditions of any Specific Agreement entered into between us and the Supplier. &nbsp;Second, Suppliers are levied a referral fee, which is only charged by us if you sell services or products to a Customer who has submitted a Request through the Site. &nbsp;The per-Customer charge and timing for payment thereof (and any other charges in connection with your use of the Site, collectively referred to as the &ldquo;<strong>Fees</strong>&ldquo;) will be as set out on the Specific Agreements &nbsp;you enter into with us, from time to time. &nbsp;Your use of the Services confirms your acceptance to such terms.</p>
<p><strong>Currency</strong>: &nbsp;All prices quoted are payable in the currency identified on the Site.</p>
<p><strong>Cancellation and Refunds</strong>: &nbsp;Your obligation to pay the Fees arises concurrently with a booking by a Customer and therefore, the Fees will not be refunded to you, by us, under any circumstances whatsoever (including, without limitation, in the event that the Customer cancels its booking, for any reason).</p>
<p><strong>Chargebacks and Disputes</strong>: &nbsp;You agree to indemnify and hold harmless Trip Merchant and its officers, directors, subsidiaries, affiliates, employees, partners, representatives, agents, licensors and their respective successors and assigns (collectively, its &ldquo;<strong>Others</strong>&ldquo;), against any chargeback costs (plus any related fees) which we are required to pay, as a result of a payment dispute with you or in relation to a payment you have made to us through the Services.</p>
<p><strong>Taxes</strong>: &nbsp;The cost of all applicable taxes arising from your use of the Services shall be added to the Fees that you are responsible for paying us.</p>
<p><strong>Third Party Payment Processor</strong>: &nbsp;Payment of the Fees is processed by and through a third party payment processor, which may be made available to you through the Services, or otherwise (such as through a Supplier). &nbsp;The processing of your payment is therefore conducted by an entity wholly separate from and independent of Trip Merchant, and we hereby disclaim absolutely any and all liability, whether, direct, indirect, vicarious or otherwise, for the processing of your payment. &nbsp;You acknowledge and agree that we provide access to such third party payment processors &ldquo;as is&rdquo; without any warranties, representations or conditions of any kind. &nbsp;Your use of third party payment processors is entirely at your own discretion and you should ensure you are familiar with, and approve, the terms on which such services are provided by the relevant third party payment processors. &nbsp;Your personal credit card details are subject to the security policies and systems employed by the third party payment processor. &nbsp;As such, you are advised to make yourself familiar with such security policies and systems. &nbsp;Other payment methods may be made available in the future, and you should read this Agreement periodically for the payment methods that may then be available.</p>
<p>&nbsp;</p>
<ol start="3">
    <li><strong>Authorized Users</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Site and the Services allow you to delegate Authorized Users to receive and respond to Requests, on your behalf. &nbsp;You are solely responsible for designating your Authorized Users, and you are solely responsible for everything an Authorized User does on your behalf, as if you had done it yourself.</p>
<p>&nbsp;</p>
<ol start="4">
    <li><strong>Partnering with Us</strong></li>
</ol>
<p>&nbsp;</p>
<p>All Supplier relationships and the entering into of Specific Agreements are subject to a review process. &nbsp;When you indicate your interest in entering into a relationship with us, we will review your application and you will be able to join the Site and the Services only once (and if) your application is approved, by us, in our sole and absolute discretion, and a Specific Agreement between you and us is entered into. &nbsp;Please note that as part of that review and approval process, we will review your business&rsquo;s website presence. &nbsp;If you do not want us to review your website for that purpose, do not sign up to become a Supplier.</p>
<p>&nbsp;</p>
<p><strong>Part 2 &ndash; Membership-Based Organizations and Employers</strong></p>
<p>&nbsp;</p>
<p>Unless otherwise indicated, Part 2 applies only to a Membership-Based Organization and Employer or Membership-Based Organizations and Employers (as those terms are described in Part 1 above). &nbsp;As used in this Part, &ldquo;<strong>you</strong>&rdquo; means (and &ldquo;<strong>your</strong>&rdquo; refers to) a Membership-Based Organization and Employer, or Membership-Based Organizations and Employers, as the case may be, and &ldquo;<strong>we</strong>&rdquo; means (and &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;, and &ldquo;<strong>ours</strong>&rdquo; refer to) Trip Merchant.</p>
<p>&nbsp;</p>
<ol start="5">
    <li><strong>Our Services</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Site provides you a Membership-Based Organization and Employer Website, which is customized to offer travel experiences and other opportunities to Customers who are affiliated with your organization, using information that is provided by Suppliers.</p>
<p>&nbsp;</p>
<ol start="6">
    <li><strong>Payment</strong></li>
</ol>
<p>&nbsp;</p>
<p>Use of the Site and the Services is free to Membership-Based Organizations and Employers. &nbsp;You just need to sign up with us, and agree to promote our Services to Customers who are affiliated with your organization, so that we can reach out to them with information about your Membership-Based Organization and Employer Website and our Services.</p>
<p>&nbsp;</p>
<ol start="7">
    <li><strong>Promotion of the Services to Customers</strong></li>
</ol>
<p>&nbsp;</p>
<p>In order for the Site to work, we need you to promote the Services to Customers who are affiliated with your organization, so that we can contact them (via email or other electronic messages), for the purpose of making them aware of the Site and offering them the opportunity to sign up. &nbsp;If you provide us any Customer names and contact information, you represent and warrant that: (a) all information you submit to us regarding Customers is true and correct; and (b) you have obtained all necessary consents to be and remain in compliance with anti-spam laws from all Customers to whom we will send electronic messages in the course of providing the Services, and you further acknowledge that we are relying on such representation and warranties, without which, we would not perform the Services.</p>
<p>&nbsp;</p>
<ol start="8">
    <li><strong>Authorized Users</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Site and the Services allow you to delegate Authorized Users to receive and respond to Requests, on your behalf. &nbsp;You are solely responsible for designating your Authorized Users, and you are solely responsible for everything an Authorized User does on your behalf, as if you had done it yourself.</p>
<p>&nbsp;</p>
<ol start="9">
    <li><strong>Partnering with Us</strong></li>
</ol>
<p>&nbsp;</p>
<p>All Membership-Based Organization and Employer relationships are subject to a review process. &nbsp;When you indicate your interest in entering into a relationship with us, we will review your application and you will be able to join the Site and the Services only once (and if) your application is approved, by us, in our sole and absolute discretion, and a Specific Agreement between you and us is entered into. &nbsp;Please note that as part of that review and approval process, we will review your business&rsquo;s website presence. &nbsp;If you do not want us to review your website for that purpose, do not sign up to become a Membership-Based Organization and Employer.</p>
<p>&nbsp;</p>
<p><strong>Part 3 &ndash; Customers</strong></p>
<p>&nbsp;</p>
<p>Unless otherwise indicated, Part 3 applies only to a Customer or Customers (as those terms are described in Part 1 above). &nbsp;As used in this Part, &ldquo;<strong>you</strong>&rdquo; means (and &ldquo;<strong>your</strong>&rdquo; refers to) a Customer, or Customers, as the case may be, and &ldquo;<strong>we</strong>&rdquo; means (and &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;, and &ldquo;<strong>ours</strong>&rdquo; refer to) Trip Merchant.</p>
<p>&nbsp;</p>
<ol start="10">
    <li><strong>Our Services</strong></li>
</ol>
<p>&nbsp;</p>
<p>The Site connects you with Suppliers, who want to offer you information and opportunities to purchase travel experiences and other products and services. &nbsp;You get to create an unlimited number of customized Requests for travel experiences and other information, and to pick which Suppliers have an opportunity to respond to your Requests. &nbsp;The Site and Services provide the opportunity for you to rate and submit Testimonials and Member Experiences about Suppliers and their products and services, allowing you and other Customers to make empowered decisions about your travel experience purchases on a go-forward basis.</p>
<p>In addition, we may from time to time provide you the opportunity to purchase travel experiences directly through the Services, under our Trip Merchant Journeys division. &nbsp;The terms and conditions of these Trip Merchant Journeys experiences may be subject to additional terms and conditions which are imposed by the destination management companies and/or inbound operator partners with whom we partner to provide you some or all of your travel experience (collectively, our &ldquo;<strong>Trip Merchant Journeys Partners</strong>&ldquo;). &nbsp;While we are pleased to be able to offer Trip Merchant Journeys experiences to you and use reasonable care to select our Trip Merchant Journeys Partners, please be advised that your Trip Merchant Journeys experiences will be dependent on the services that are offered by our Trip Merchant Journeys Partners, as well as conditions which are specific to the destinations to which you travel, such as travel documentation and entry and exit requirements, baggage restrictions and local conditions, such as weather and political risks. &nbsp;We strongly encourage you to review the terms and conditions of any Trip Merchant Journeys Partner&rsquo;s services that apply to your experience.</p>
<p>If you purchase a travel experience through our Trip Merchant Journeys division, the following additional terms and conditions will apply:</p>
<ul>
    <li>Payment-specific terms, such as the time when payments are due, whether there are installments, the amount of the deposit that is due (if any) and the consequences of late payments, will be as are set out in any additional information we will provide to you, when you purchase the travel experience. Your agreement to purchase a travel experience through our Trip Merchant Journeys division constitutes your acceptance of these additional payment-specific terms.</li>
    <li>You will provide medical information that we may reasonably request from you, in order to provide you your travel experience.</li>
    <li>You will provide us proof of travel insurance, covering such risks and amounts as we may reasonably require, depending on the nature of the particular travel experience you have chosen.</li>
    <li>We will communicate to you any refund procedures that may be in place, in the event you cancel your travel experience. Unless otherwise indicated to you by us, in writing, all payments are non-refundable.</li>
    <li>You are responsible for ensuring you have sufficient travel documentation which allows you to enter and exit your destination.</li>
    <li>We may need to make changes to your itinerary. If a change is required that materially affects your travel experience (as determined by us, acting reasonably), we will contact you and work with you on a mutually agreeable outcome.</li>
</ul>
<p>&nbsp;</p>
<ol start="11">
    <li><strong>Payment</strong></li>
</ol>
<p>&nbsp;</p>
<p>Use of the Site and the Services is free to Customers &ndash; you just need to sign in to receive a username and a password (unless you purchase a Trip Merchant Journeys experience directly through the Services, in which case, the payment terms set out in&nbsp;<strong>Section 2&nbsp;</strong>of Part 1 of this Agreement will apply).</p>
<p>&nbsp;</p>
<ol start="12">
    <li><strong>Information for Requests</strong></li>
</ol>
<p>&nbsp;</p>
<p>When you provide information to populate your Requests, you need to provide the Site information which is used by Suppliers to respond to Requests. &nbsp;You represent and warrant that: (a) you are legally entitled to submit the Request to the Site, in the manner you describe the Request; (b) all information you submit to us regarding the Request is true and correct; and (c) you will not assert or allege that the Supplier&rsquo;s response to your Request is contrary to privacy or anti-spam laws, insofar as you have specifically requested such a Response.</p>
<p>&nbsp;</p>
<p><strong>Part 4 &ndash; General Terms and Conditions</strong></p>
<p>&nbsp;</p>
<p>Unless otherwise indicated, Part 4 applies to all users of the Site. &nbsp;As used in this Part, &ldquo;<strong>you</strong>&rdquo; means (and &ldquo;<strong>your</strong>&rdquo; refers to) the user of the Site, &ldquo;<strong>we</strong>&rdquo; means (and &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;, and &ldquo;<strong>ours</strong>&rdquo; refer to) Trip Merchant. &nbsp;Terms which are defined in Part 1, Part 2 or Part 3 have the same meaning throughout this Agreement, notwithstanding that Part 1 only applies to Suppliers, Part 2 only applies to Membership-Based Organizations and Employers and Part 3 only applies to Customers.</p>
<p>&nbsp;</p>
<ol start="13">
    <li><strong>No Recourse and Responsibility for Third Party Products and Services</strong></li>
</ol>
<p>&nbsp;</p>
<p>In order to offer group bookings as a Service, or Trip Merchant Journeys travel experiences, we need to enter into agreements with third party Suppliers and Trip Merchant Journeys Partners, to provide you those Services and travel experiences. &nbsp;While we use reasonable efforts to select third party Suppliers and Trip Merchant Journeys Partners that we believe will provide you Services that will be enjoyable and of a quality that is consistent with industry standards, please note that we cannot control the performance of these Suppliers and Trip Merchant Journeys Partners. &nbsp;Since we cannot control or supervise these Suppliers and Trip Merchant Journeys Partners, WE CANNOT BE HELD RESPONSIBLE FOR ANY LOSS OR HARM YOU SUFFER, AS A CONSEQUENCE OF ANYTHING THESE SUPPLIERS AND TRIP MERCHANT JOURNEYS PARTNERS DO (OR FAIL TO DO, AS THE CASE MAY BE). &nbsp;You should be aware that if Trip Merchant does coordinate group bookings through the Services or offer Trip Merchant Journeys travel experiences, this Agreement shall apply to that activity, together with any Specific Agreements which may be entered into between you and us, related to that group booking or travel experience. &nbsp;You acknowledge that your use of services that are provided by these Suppliers and Trip Merchant Journeys Partners may be subject to specific terms and conditions which are set by them.</p>
<p>&nbsp;</p>
<p>Except as indicated above in this&nbsp;<strong>Section&nbsp;</strong><strong>13</strong>, Trip Merchant (as the owner and operator of the Site and the Services), Suppliers, Membership-Based Organizations and Employers, Trip Merchant Journeys Partners and Customers are all fully independent contracting parties. &nbsp;Any products or services offered by a Supplier or Trip Merchant Journeys Partner and accepted by a Customer, are offered for sale and accepted independently from, and to the full exclusion of, Trip Merchant, at a price which is set at the full, sole and exclusive discretion of the Supplier or Trip Merchant Journeys Partner and to the full exclusion of Trip Merchant. &nbsp;Any price suggested by a Supplier or Trip Merchant Journeys Partner is subject to negotiation between the Supplier or Trip Merchant Journeys Partner and the Customer, on their own behalf and for their own benefit, as fully competent contracting parties independent of Trip Merchant. &nbsp;For greater certainty, Trip Merchant provides an enabling platform to connect Suppliers and Customers, only.</p>
<p>&nbsp;</p>
<p>In addition, your use of the Site and the Services may rely on information which is offered by Suppliers, Membership-Based Organizations and Employers, Trip Merchant Journeys Partners and Customers (as the case may be). &nbsp;We have no responsibility to you for anything these third parties do (or fail to do) and we provide no warranties or guarantees about third parties or the information or services they provide. &nbsp;Your use of such services may be subject to specific terms and conditions which are set by those third parties. &nbsp;Further, we are not a party to any relationship between you and any third party which is formed as a result of a response to a Request, or otherwise through the Site or the Services, including, but not limited to, you and a Supplier, Trip Merchant Journeys Partner or a Customer (as the case may be), and as such, we have no responsibility to you as regards to your relationships with these third parties. &nbsp;You acknowledge and agree that you have no recourse against us for any acts or omissions of third parties, and your interaction with third parties is entirely at your own risk.</p>
<p>&nbsp;</p>
<ol start="14">
    <li><strong>Consent to Electronic Communication</strong></li>
</ol>
<p>&nbsp;</p>
<p>When you visit the Site, use services which are provided on or through the Site (including the Services) or send email or text messages to us, you are communicating with us electronically. &nbsp;You consent to receive communications from us electronically. &nbsp;We will communicate with you by email, text messages or by posting notices on the Site. &nbsp;You agree that all agreements, notices, disclosures and other communications that we provide to you electronically, satisfy any legal requirement that such communications be in writing and/or signed.</p>
<p>&nbsp;</p>
<ol start="15">
    <li><strong>Modification to the Site</strong></li>
</ol>
<p>&nbsp;</p>
<p>We may revise this Agreement, at any time, by updating this posting. &nbsp;We may provide notice to you of material revisions by means of a general notice on the Site. &nbsp;Your continued use of the Site after such revisions are posted, will signify your agreement to these revised terms. &nbsp;Therefore, you should visit this page periodically to review this Agreement.</p>
<p>&nbsp;</p>
<ol start="16">
    <li><strong>Privacy</strong></li>
</ol>
<p>&nbsp;</p>
<p>By accessing and continuing to use the Site, and by providing personal information to us through the Site, you are indicating your acceptance to be bound by the terms and conditions of Trip Merchant&rsquo;s Website Privacy Policy. &nbsp;If you do not accept the terms and conditions set forth in Trip Merchant&rsquo;s Website Privacy Policy, please do not access or use the Site or provide personal information through the Site.</p>
<p>&nbsp;</p>
<ol start="17">
    <li><strong>Your Login Credentials</strong></li>
</ol>
<p>&nbsp;</p>
<p>You may be required, when you use certain features of the Site (including, without limitation, the Services), to create a user name and password. &nbsp;If we determine that the user name you have chosen is in use by someone else or it is otherwise offensive, we may refuse to allow you, in our sole discretion, to use your chosen user name. &nbsp;In addition, you are responsible for maintaining the confidentiality of your password and you are responsible for all uses of your user name, whether by Authorized Users or otherwise, and whether or not you authorize such uses. You agree to notify us of any unauthorized use of your user name and password. &nbsp;We are not responsible for verifying your identity or the identity of anyone who uses your login credentials, and we are not liable for any loss or damage as a result of your failure to protect your password. &nbsp;You agree that any registration information you provide will be true and accurate. &nbsp;We reserve the right to automatically log you out of your login credentials after such a period of inactivity as we determine is reasonable, in the circumstances.</p>
<p>&nbsp;</p>
<ol start="18">
    <li><strong>Ownership of Content</strong></li>
</ol>
<p>&nbsp;</p>
<p><strong>Our Content</strong>:<strong>&nbsp;&nbsp;</strong>Except for Your Content (which is discussed below), all materials displayed or otherwise accessible on the Site, including, without limitation, text, photographs, images, illustrations, graphics, icons, code (collectively, &ldquo;<strong>Our Content</strong>&ldquo;) and the selection and arrangement of Our Content on the Site are protected by copyright, pursuant to Canadian copyright laws, international conventions and other copyright laws. &nbsp;We either own the intellectual property rights, including copyright, or have acquired the necessary rights or licences, in and to Our Content. &nbsp;Portions of Our Content may have been licensed to us or published on the Site by third parties. &nbsp;Any reproduction, modification, publication, transmission, transfer, sale, distribution, display or exploitation of the Site or Our Content, whether in whole or in part, without our express written permission, is strictly prohibited. &nbsp;The display of Our Content does not imply that you have been granted a license by us or other entities with respect to it. &nbsp;For greater certainty, and with respect to Membership-Based Organization and Employer Websites, we have sole control and ownership of the content which is placed on Membership-Based Organization and Employer Websites (provided that ownership of material which is provided to us by you, for use on your Membership-Based Organization and Employer Website, shall continue to be held by you, and be deemed to be Your Content). &nbsp;If you do not like the content of your Membership-Based Organization and Employer Website, your sole remedy is to notify us that you no longer wish to receive the Services.</p>
<p><strong>Your Content</strong>: &nbsp;You retain ownership in and to materials you submit to us, including, without limitation, text (including Testimonials and Member Experiences), photographs, images, illustrations, graphics and icons (collectively, &ldquo;<strong>Your Content</strong>&ldquo;), but you agree to grant us a sub-licensable, irrevocable licence to use Your Content, as we see fit, in our sole discretion, for the purpose of providing the Services and in connection with any sale of Trip Merchant in whole or in part, including the assets thereof. &nbsp;We reserve the right to disclose Your Content, at any time, as is required to comply with applicable laws, and the right to remove any of Your Content that is in violation of the terms and conditions of this Agreement. &nbsp;You also agree that any rights you assert in and to Your Content will be subject to standards of commercial reasonableness. &nbsp;You represent and agree that you will not post to the Site or the Services, any trade secrets, confidential information, intellectual property and other proprietary information of any other person, without authorization from such other people, or any obscene or defamatory content. &nbsp;Your Content (and our use thereof in accordance with this Agreement) shall not knowingly infringe the intellectual property rights, including copyright, patents, trade-marks or trade secrets, of any third party. &nbsp;You hereby indemnify and hold harmless, Trip Merchant and its Others, from any and all liability or loss, including reasonable counsel fees, which we suffer in connection with any claim or action by reason of a breach of the foregoing representations and warranties.</p>
<p><strong>Reporting infringement</strong>:<strong>&nbsp;&nbsp;</strong>If you believe in good faith that any material that is made available on the Site infringes your copyright, please contact us at info@tripmerchant.com.</p>
<p><strong>Trademarks</strong>: &nbsp;Certain words, phrases, names, designs or logos on the Site may constitute trademarks, services marks, or trade names that are owned by us or others. &nbsp;The display of such marks on the Site does not imply that you have been granted a licence by us or others with respect to them.</p>
<p>&nbsp;</p>
<ol start="19">
    <li><strong>Limited Licence</strong></li>
</ol>
<p>&nbsp;</p>
<p>Subject to your compliance with the terms and conditions of this Agreement, we hereby grant to you:</p>
<ul>
    <li>a limited, non-exclusive, non-assignable, revocable right to use the Services, for the purpose of submitting and receiving information related to Requests. This right terminates upon termination of this Agreement or any other agreements previously provided to you (including but not limited to Specific Agreements). &nbsp;Any and all rights not expressly granted to you are reserved by us, and this Agreement does not confer to you a proprietary interest in any Services or the Site; and</li>
    <li>a limited personal, non-exclusive, non-transferable, revocable license to access, view and use our Site, including a limited license to download, print and store single copies of Our Content from our website, for your personal use, provided that you maintain all copyright and other notices contained in such items and you do not (and do not allow any third party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover, any source code, in our website. Our Content must not be reproduced, republished, or disseminated in any manner or form without our prior written consent or the prior written consent of the third parties from which such information was collected, as the case may be.</li>
</ul>
<p>&nbsp;</p>
<ol start="20">
    <li><strong>Prohibited Uses</strong></li>
</ol>
<p>&nbsp;</p>
<p>While you use the Site and the Services, you must comply with all applicable laws, rules and regulations. In addition, use of the Site and the Services is based on the following rules of conduct. &nbsp;You will not:</p>
<ul>
    <li>post, transmit, or otherwise make available any material that is or may be (a) threatening, harassing, degrading, hateful, or intimidating; (b) defamatory; (c) fraudulent or tortious; (d) obscene, indecent, pornographic, or otherwise objectionable; or (e) an infringement of another person&rsquo;s privacy by disclosing the personal information of another individual without their knowledge and consent;</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>post, transmit, or otherwise make available, any material that may violate the proprietary rights of any third party, including, without limitation, copyrighted software, photographs, texts, videos or artwork or any moral rights associated therewith;</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>use the Site in a manner which will violate or solicit the violation of any applicable local, provincial, territorial, state, national or international law, rule or regulation, including, without limitation, applicable privacy laws and the statute known as&nbsp;<em>An Act to promote the efficiency and adaptability of the Canadian economy by regulating certain activities that discourage reliance on electronic means of carrying out commercial activities, and to amend the Canadian Radio-television and Telecommunications&nbsp;</em>(Canada) (&ldquo;<strong>CASL</strong>&ldquo;);</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>use the Site to post transmit, or otherwise make available any material which would: (a) give rise to criminal or civil liability; (b) encourage conduct that constitutes a criminal offence; or (c) encourage or provide instructional information about illegal activities;</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>impersonate any person or entity, including, but not limited to, any of our representatives; falsely state or otherwise misrepresent your affiliation with any person or entity; or express or imply that we endorse any statement you make without our prior written consent;</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>post, transmit, or otherwise make available any virus, worm, trojan horse, spyware, or any other computer code, file, or program that may, or is intended to, damage or hijack the operation of any hardware, software, server or telecommunications equipment;</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>conduct any systematic or automated data collection activities (including, without limitation, scraping, data mining, data extraction and data harvesting) on or in relation to the Site, without our prior written consent;</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>interfere with or disrupt the Site;</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>post, transmit, or otherwise make available any unsolicited or unauthorized advertising, promotional materials, &ldquo;junk mail,&rdquo; &ldquo;spam,&rdquo; &ldquo;chain letters,&rdquo; &ldquo;pyramid schemes,&rdquo; investment opportunity, or any other form of solicitation, including by, without limitation, use of email addresses and other contact information provided by us, Suppliers, Membership-Based Organizations and Employers or Customers, for purposes other than as part of the Services;</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>use the Site for any commercial purposes other than those which are expressly set out in this Agreement; or</li>
</ul>
<p>&nbsp;</p>
<ul>
    <li>use the Site if you are a minor, except in accordance with applicable laws, and with the approval of your parent or guardian.</li>
</ul>
<p>Any person who is found, or reasonably suspected, to have violated the rules of conduct provided above may be barred from using the Site, in our sole discretion, and may be subject to other legal remedies.</p>
<p>&nbsp;</p>
<ol start="21">
    <li><strong>Practices Regarding Use and Storage</strong></li>
</ol>
<p>&nbsp;</p>
<p><strong>Storage or Deletion of Content</strong>: &nbsp;You agree that we have no responsibility or liability for the deletion of, or the failure to store or to transmit, any information maintained by us, including, without limitation, Your Content and Our Content.</p>
<p><strong>Limits on Storage</strong>: &nbsp;We retain the right to create limits on use and storage, at our sole discretion, at any time, with or without notice. &nbsp;The Site and the Services currently store all Requests, until such time as the Supplier or Suppliers to whom the Request was submitted choose to delete the Request.</p>
<p><strong>Our Access to Stored Content</strong>: &nbsp;We also reserve the right to access, read, preserve, and disclose any information in a manner we reasonably believe is necessary, including, without limitation, Your Content, to: (a) satisfy any applicable law, regulation, legal process or governmental request; (b) enforce this Agreement, including investigation of potential violations hereof; (c) detect, prevent, or otherwise address fraud, security or technical issues; (d) respond to user support requests; or (e) protect our rights, property or safety, our users or the public. &nbsp;We will not be responsible or liable for the exercise or non-exercise of our rights under this Agreement.</p>
<p><strong>Monitoring</strong>: &nbsp;We have the right to access the Services, Our Content and Your Content at any time, to maintain their effective operation, to provide upgrades to the software or other system components and to review the your use of the Services, Our Content and Your Content. &nbsp;We reserve the right, but do not assume the responsibility or obligation, to monitor, view and audit transactions and communications (inbound and outbound, including Requests and responses to Requests), in our sole and absolute discretion, in order to manage the Site and the Services and for such other purposes as we shall deem necessary or advisable. &nbsp;If we determine, in our sole and absolute discretion, that you have breached or will breach a term or condition of this Agreement, we may cancel any Request, or related matter which occurs on or through the Site, or take any other action to restrict access to or the availability of any material that may be considered objectionable, without any liability to you or any third party. &nbsp;Further, in such event, we may, in our sole and absolute discretion, immediately terminate this Agreement and your access to the Site and the Services, all without notice to you.</p>
<p>&nbsp;</p>
<p><strong>Industry Analysis</strong>: &nbsp;We reserve the non-expiring right to use and disclose (for commercial purposes or otherwise) the textual and numerical statistical portion of all data in the Site and the Services at any time to conduct analysis for industry trends, provided that such data shall not be singularly isolated and labeled as a particular Supplier&rsquo;s, Membership-Based Organization and Employer&rsquo;s or Customer&rsquo;s data.</p>
<ol start="22">
    <li><strong>Maintenance Outages</strong></li>
</ol>
<p>We reserve the right, as reasonably necessary or convenient, either for our own purposes or to improve the quality of any of the components comprising the Site or the Services, to change rules of operation for the technologies therein comprised, system interfaces, utilities, operating and other systems and software, and to implement enhancements, amendments or updates thereto. &nbsp;To minimize the effect of service outages, we will undertake reasonable efforts to schedule times during which the Site or the Services will be unavailable to you, due to maintenance.</p>
<p>&nbsp;</p>
<ol start="23">
    <li><strong>Customer Service</strong></li>
</ol>
<p>&nbsp;</p>
<p>We may make customer service features available to you, as a courtesy only. &nbsp;We are not obliged to provide customer service to you and our decision to do so shall not create an obligation to provide additional customer service to you.</p>
<p>&nbsp;</p>
<ol start="24">
    <li><strong>Authorization</strong></li>
</ol>
<p>&nbsp;</p>
<p>If you are accepting the terms and conditions contained in this Agreement on behalf of a Membership-Based Organization and Employer or a Supplier, you represent and warrant that you have full legal authority to bind the Membership-Based Organization and Employer or a Supplier and all of the Authorized Users thereof.</p>
<p>&nbsp;</p>
<ol start="25">
    <li><strong>Linking and other Sites</strong></li>
</ol>
<p>&nbsp;</p>
<p><strong>No Endorsement or Responsibility</strong>:<strong>&nbsp;&nbsp;</strong>The Site may include links to other websites (including those of Suppliers and Membership-Based Organizations and Employers), solely as a convenience to users. &nbsp;We do not endorse any such linked sites or the information, material, products or services contained on other linked sites or accessible through other linked sites. &nbsp;Furthermore, we make no express or implied warranties with regard to the information, material, products or services that are contained on or accessible through linked sites.</p>
<p><strong>Third Party Content</strong>: &nbsp;We may make third party content and material (&ldquo;<strong>Third Party Content</strong>&ldquo;) available to you through the Site and the Services (including, but not limited to, Testimonials and Member Experiences). &nbsp;Our making available of such Third Party Content does not constitute an endorsement or recommendation, and we are not responsible for any reliance you may place on Third Party Content. &nbsp;We make no warranties or representations as to any Third Party Content and we shall have no liability for it. &nbsp;Any opinions or statements made by third parties are those of such third parties, and do not necessarily state or reflect our views.</p>
<p><strong>Use at Your Own Risk</strong>:<strong>&nbsp;&nbsp;</strong>Your access to and use of linked sites, including information, materials, products and services on linked sites or available through linked sites, is solely at your own risk and governed by the terms of service of the linked site. &nbsp; If there is any conflict between this Agreement and any of the terms or notices set forth on any other website, then the terms of that other website will control your use of information and content you access through that link. &nbsp;Please review the terms of service for each link so that you understand all of the terms that will apply.</p>
<p><strong>Linking to the Site</strong>:<strong>&nbsp;&nbsp;</strong>Please advise us to request permission to link to the Site. &nbsp;We reserve the right to cancel and revoke any permission we may give to link to the Site at any time, for any reason, without any notice, and without any liability to you or any other person.</p>
<p>&nbsp;</p>
<ol start="26">
    <li><strong>No Warranty</strong></li>
</ol>
<p>&nbsp;</p>
<p><strong>Use at Your Own Risk</strong>: &nbsp;Access to the Site and the Services and the materials provided on the Site and the Services are provided &ldquo;as is&rdquo; and without warranties of any kind either express or implied. BY USING THE SITE AND THE SERVICES, YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SITE AND THE SERVICES IS ENTIRELY AT YOUR OWN RISK.</p>
<p><strong>No Warranty as to Accuracy</strong>: &nbsp;We make no representation or warranties regarding the Site or materials on the Site, including, without limitation, that the Site or materials on the Site (including but not limited to Third Party Content) will be accurate, complete, correct, timely or suitable, that any products and Services contained on or made available through the Site are of merchantable quality or fit for a particular purpose, that the Site will be available at all times or that the Site will be free from errors, viruses or other harmful components. &nbsp;We are not responsible for any materials, submissions or other information on the Site or any linked site that you may find offensive, undesirable or objectionable. &nbsp;WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, AND TO THE FULL EXTENT PERMITTED UNDER APPLICABLE LAW, TRIP MERCHANT AND ITS OTHERS SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, BY STATUTE OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
<p><strong>Third Party Content and Services</strong>: &nbsp;We specifically disclaim any responsibility or liability for the accuracy, completeness, correctness, timeliness or usefulness of Third Party Content. &nbsp;To the fullest extent permissible pursuant to applicable law, we disclaim all warranties, express or implied, including, but not limited to, warranties and conditions regarding the use of Third Party Content, including all implied warranties or conditions of merchantability, fitness for a particular purpose or non-infringement, whether express or implied, or arising from a course of dealing, usage or trade practice. &nbsp;We hereby disclaim any and all liability for the outcome of any services offered by Suppliers, Trip Merchant Journeys Partners, or any other third parties. &nbsp;We are not responsible for verifying the veracity of claims made by any Supplier or Trip Merchant Journeys Partner as to the qualifications they possess, and shall not be held liable for any such claims which may be proven to be unlawful, deceptive, misleading, fraudulent or tortious, nor are we involved with, or responsible for, the Customer&rsquo;s decision to purchase services or products from a Supplier or Trip Merchant Journeys Partner.</p>
<p>WE ARE NOT A PARTY TO THE RELATIONSHIP BETWEEN YOU AND ANY OTHER SUPPLIER, MEMBERSHIP-BASED ORGANIZATION AND EMPLOYER, TRIP MERCHANT JOURNEYS PARTNER OR CUSTOMER, AND AS SUCH, TRIP MERCHANT AND ITS OTHERS HAVE NO RESPONSIBILITY TO YOU AS REGARDS: (A) THE PRODUCTS AND SERVICES YOU PURCHASE OR SELL TO OR FROM SUPPLIERS, MEMBERSHIP-BASED ORGANIZATIONS AND EMPLOYERS, TRIP MERCHANT JOURNEYS PARTNER OR CUSTOMERS (AS THE CASE MAY BE); (B) YOUR INTERACTION WITH ANY OTHER SUPPLIER, MEMBERSHIP-BASED ORGANIZATION AND EMPLOYER, TRIP MERCHANT JOURNEYS PARTNER OR CUSTOMER; (C) ANY TRANSACTIONS YOU MAY ENTER INTO OR ATTEMPT TO ENTER INTO, WITH ANY OTHER SUPPLIER, MEMBERSHIP-BASED ORGANIZATION AND EMPLOYER, TRIP MERCHANT JOURNEYS PARTNER OR CUSTOMER; (D) COLLECTING OR REMITTING ANY APPLICABLE TAXES, IN RESPECT OF SUCH TRANSACTIONS. &nbsp;YOU AGREE THAT YOU WILL HAVE NO RECOURSE OR REMEDY AGAINST TRIP MERCHANT AND ITS OTHERS, EXCEPT AS EXPRESSLY SET OUT IN THIS AGREEMENT.</p>
<p><strong>Insecurity of Internet</strong>: &nbsp;You should note that the Internet is an inherently insecure medium, and sending any online communication provides no guarantee of successful delivery. &nbsp;Care should be taken to ensure that the content of your online communication is not sensitive or confidential, since we can take no responsibility for communication which is intercepted, lost, delayed, or misdelivered. &nbsp;Since your computer equipment and the general reliability and performance of the Internet is outside our control, we do not warrant your performance will achieve expected or implied levels. &nbsp;Be aware that information you share on and through the Site and the Services will be available to others. &nbsp;We are not responsible for what others do with such shared materials and other information, once you post them on or through the Site and the Services. &nbsp;We are also not responsible for any unauthorized access to, or use of, the Site and the Services, and/or any and all personal information or financial information stored on or through the Site and the Services; any loss of your data or content through the Site and the Services; or the violation of your rights by any third party.</p>
<p>&nbsp;</p>
<ol start="27">
    <li><strong>Limitation</strong><strong>&nbsp;of Liability, Release and Indemnity</strong></li>
</ol>
<p>&nbsp;</p>
<p>THIS SECTION LIMITS OUR LIABILITY TO YOU AND PROVIDES FOR A RELEASE AND AN INDEMNITY, FROM YOU, IN FAVOUR OF US. &nbsp;PLEASE READ IT CAREFULLY.</p>
<p><strong>LIMITATION OF LIABILITY</strong>: &nbsp;UNDER NO CIRCUMSTANCES SHALL YOU BE ENTITLED TO RECOVER ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL OR INDIRECT DAMAGES FROM TRIP MERCHANT OR ITS OTHERS WHICH YOU MAY SUFFER ARISING, CAUSED, ARISING OUT OF, OR IN CONNECTION WITH, THE USE OF, OR INABILITY TO USE, THE SITE OR THE SERVICES, ANY ACCURACY, INCOMPLETENESS, OR INCORRECTNESS CONTAINED ON THE MATERIALS DISPLAYED, ACCESSED OR USED ON THE SITE, OR YOUR RELIANCE OR ACTING UPON THE MATERIALS ON THE SITE, INCLUDING, ANY LOSS OR DAMAGES IN THE NATURE OF OR RELATING TO LOST BUSINESS, LOST SAVINGS, LOST DATA, UNAUTHORIZED ACCESS TO OR USE OF THE SITE AND THE SERVICES AND DATA CONTAINED ON OR THROUGH THE SITE OR THE SERVICES AND/OR PROFITS, REGARDLESS OF THE CAUSE AND WHETHER ARISING IN CONTRACT, TORT, IN EQUITY, AT LAW OR OTHERWISE AND WHETHER OR NOT TRIP MERCHANT HAS OR HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. &nbsp;UNDER NO CIRCUMSTANCES SHALL THE LIABILITY OF TRIP MERCHANT AND ITS OTHERS TO YOU FOR DAMAGES SUFFERED BY YOU ARISING OUT OF, RELATED TO OR CAUSED BY THE SITE, THE SERVICES OR THE USE THEREOF, EXCEED A MAXIMUM AMOUNT EQUAL TO THE AMOUNT ACTUALLY PAID BY YOU FOR THE SERVICES IN THE SIX-MONTHS&rsquo; PERIOD WHICH PRECEDES THE OCCURRENCE OF SUCH DAMAGES. &nbsp;YOU ACKNOWLEDGE THAT THE FEES PAID BY YOU REFLECT THE ALLOCATION OF RISK SET FORTH IN THIS AGREEMENT AND THAT TRIP MERCHANT WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS ON LIABILITY.</p>
<p><strong>RELEASE</strong>: &nbsp;YOU AGREE TO RELEASE, REMISE, ACQUIT AND DISCHARGE TRIP MERCHANT, AND ITS OTHERS FROM ANY CLAIMS, ACTIONS, DEMANDS, COSTS AND EXPENSES OF ANY KIND WHATSOEVER, WHETHER IN CONTRACT, NEGLIGENCE OR TORT, AT LAW OR IN EQUITY, OR BY STATUTE OR OTHERWISE, HOWSOEVER CAUSED, WITH RESPECT TO YOUR USE OF, OR OUR OPERATION OF, THE SITE AND THE SERVICES, EXCEPT AS MAY BE SET OUT IN THE PRECEDING PARAGRAPH.</p>
<p><strong>INDEMNITY:</strong>&nbsp; You agree to indemnify, save and hold harmless Trip Merchant and its Others, from and against any claims, actions, demands, judgments, awards, declarations, orders, settlements, damages (including general, special, punitive, aggravated or exemplary damages), liabilities, losses, costs, charges, interest and expenses, or proceedings of any kind whatsoever which may be initiated or presented by any other persons, individuals or other legal entities, and which arise directly or indirectly from your use of or reliance on the Site or the Services or information available on or through the Site or the Services. &nbsp;Without limiting the generality of the foregoing, you hereby agree to pay all costs, fees and expenses, on a solicitor and own client basis, which may be incurred by Trip Merchant and its Others, relating to your use of or reliance on the Site or the Services and information available on or through the Site or the Services.</p>
<p><strong>CASL</strong>: &nbsp;With respect to CASL, you agree to HOLD HARMLESS AND INDEMNIFY Trip Merchant and its Others from any and all liability for any loss, damage, expense or injury, arising from your: (a) breach of a warranty set out in&nbsp;<strong>Sections 7</strong>,<strong>&nbsp;12&nbsp;</strong>or&nbsp;<strong>20</strong>; or (b) failure to comply with CASL, including in both cases, without limitation, any and all actual or threatened claims, demands, actions, causes of action, liabilities, losses, damages, fines, penalties (including, without limitation, administrative monetary penalties), costs and expenses (including, without limitation, legal fees on a solicitor-and-own client basis, investigation fees and disbursements).</p>
<p>SINCE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF DAMAGES OR LIABILITY, SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION 27 MAY NOT APPLY TO YOU.</p>
<p>&nbsp;</p>
<ol start="28">
    <li><strong>Conflict of Laws</strong></li>
</ol>
<p>&nbsp;</p>
<p><strong>Application of Alberta Laws</strong>: &nbsp;We are physically located within the Province of Alberta, Canada. &nbsp;This Agreement will be governed by the laws of the Province of Alberta and the laws of Canada applicable therein and shall be treated in all respects as an Alberta contract, without reference to the principles of conflicts of law. In the event of a dispute, you agree to submit to the exclusive jurisdiction of Alberta&rsquo;s courts.</p>
<p><strong>Laws Excluded</strong>: &nbsp;We expressly exclude the UN Convention on Contracts for the International Sale of Goods, and Part 2 of the&nbsp;<em>International Conventions Implementation Act&nbsp;</em>(Alberta), R.S.A. 2000, c I-6, as amended, replaced or re-enacted from time to time.</p>
<p><strong>Waiver of Rights</strong>: &nbsp;You agree to waive any right you may have to: (a) a trial by jury; and (b) commence or participate in any class action against us related to your use of the Site, the exchange of electronic documents between us or this Agreement and, where, applicable, you also agree to opt out of any class proceedings against us or our licensors.</p>
<p><strong>Agreement in English</strong>:<strong>&nbsp;&nbsp;</strong>The parties hereto have required that this Agreement and all documents relating thereto be drawn up in English. Nous avons demand&eacute; que cette convention ainsi que tous les documents qui s&rsquo;y rattachent soient r&eacute;dig&eacute;s en anglais.</p>
<p>&nbsp;</p>
<ol start="29">
    <li><strong>International Matters</strong></li>
</ol>
<p>&nbsp;</p>
<p>Unless otherwise specified, the Site is presented solely for the purpose of promoting products and services available in Canada. &nbsp;We make no representation that the Site, Our Content and all other materials on the Site are appropriate or available for use in any particular location. &nbsp;Those who choose to access the Site, do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
<p>&nbsp;</p>
<ol start="30">
    <li><strong>Force Majeure</strong></li>
</ol>
<p>&nbsp;</p>
<p>We will not be liable for failure or delay in our performance of our obligations under this Agreement due to any cause beyond our reasonable control, including, but not limited to: (a) acts of God; or (b) failure or disruptions in third-party-controlled or operated communications facilities; or (c) worms, viruses and other disabling or disruptive software, communications or files.</p>
<p>&nbsp;</p>
<ol start="31">
    <li><strong>Termination</strong></li>
</ol>
<p>&nbsp;</p>
<p><strong>Suspension of your Use of the Site</strong>:<strong>&nbsp;&nbsp;</strong>If you breach any provision of this Agreement, you may no longer use the Site. &nbsp;We may at any time and for any reason, with or without cause, and in our sole discretion, immediately suspend or terminate (in whole or in part) your permission to use the Site or the Services, all without any notice or liability to you or any other person.</p>
<p><strong>No Refunds or Rights on Termination</strong>:<strong>&nbsp;&nbsp;</strong>We shall not be responsible for refunding or otherwise paying any funds, amounts or credits that we may owe to you if we have suspended or terminated your permission to use the Site or any part thereof. &nbsp;We reserve the right to cease, suspend or interrupt operation of or access to the Site or any part thereof, and shall not be required to provide any notice in respect of such cessation, suspension or interruption of access, nor shall we have any liability for such cessation, suspension or interruption of access.</p>
<p>&nbsp;</p>
<ol start="32">
    <li><strong>Survival</strong></li>
</ol>
<p>&nbsp;</p>
<p>Any provision of this Agreement that must survive to fulfill its essential purpose (whether expressly stated as such or not) and any obligation you have to pay fees incurred before termination will survive the termination of this Agreement.</p>
<p>&nbsp;</p>
<ol start="33">
    <li><strong>Miscellaneous</strong></li>
</ol>
<p>&nbsp;</p>
<p>Our failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. &nbsp;If any provision of these terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these terms and shall not affect the validity and enforceability of any remaining provisions. &nbsp;We may assign this Agreement without restriction. &nbsp;The posting of email addresses on the Site and the Services does not, unless otherwise expressly indicated or as part of the operation of the Services, constitute consent (express or implied) for you to send unsolicited commercial electronic messages, to such email addresses. &nbsp;This Agreement constitutes the entire agreement between Trip Merchant and each Supplier, Membership-Based Organization and Employer and Customer, pertaining to the subject matter hereof, and supersedes all prior formal and informal agreements, proposals, promises, inducements, representations, conditions, warranties, understandings, negotiations and discussions, whether oral or written, between Trip Merchant and each Supplier, Membership-Based Organization and Employer and Customer (as the case may be), except for any Specific Agreements that may be entered into, between Trip Merchant and a Supplier, a Membership-Based Organization and Employer or a Customer. &nbsp;In the event of conflict between this Agreement and a Specific Agreement, the terms of the Specific Agreement shall prevail and govern.</p>
<p>&nbsp;</p>
<ol start="34">
    <li><strong>Our Contact Information</strong></li>
</ol>
<p>&nbsp;</p>
<p>If you have any questions about this Agreement, or the Site generally, please contact us at:</p>
<p>&nbsp;</p>
<p>Trip Merchant Ltd.</p>
<p>info@tripmerchant.com</p>
<p><strong><em>Last Revised: &nbsp;February 8, 2019</em></strong></p>`

const TermAndConditions = () => {
    const [data, setData] = useState();

    useEffect(() => {
        getData("/pages/16").then((res) => {
          setData(res?.data);
        });
      }, []);
  return (
    <>
      <Title title={data?.attributes?.Title || "Trip Merchant | Terms of use"} />
      <HeroSection
        heading={"TRIP MERCHANT"}
        title={"TERMS OF SERVICE"}
        image={require("../../assets/images/whoweare.jpg")}
        // image={strapiUrl + (data?.attributes?.blooks?.[0]?.Cards?.[0]?.image?.data?.attributes?.url) || require("../../assets/images/whoweare.jpg")}
        bannerClass={"justify-content-center text-center"}
        //Tag={"h4"}
        color="#F25926"
      />
      <div className="container my-3">
        <h3 className="text-center mt-2">{data?.attributes?.blooks[0]?.Cards[0]?.Title || "TERMS OF SERVICE"}</h3>
        <div
        //   dangerouslySetInnerHTML={{
        //     __html: data?.attributes?.blooks[0]?.Cards[0]?.detail,
        //   }}
          />
          <ReactMarkdown>{data?.attributes?.blooks[0]?.Cards[0]?.detail || "Loading..."}</ReactMarkdown>
      </div>
    </>
  );
};

export default TermAndConditions;
