import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { HeroSection } from "../../components/HeroSection";
import { getData } from "../../common/strapiUrl";
import ReactMarkdown from "react-markdown";

const policy = `<p>This Trip Merchant Website Privacy Policy (this &ldquo;<strong>Privacy Policy</strong>&ldquo;) governs your access to and use of the websites, services and applications (collectively, the &ldquo;<strong>Site</strong>&ldquo;) which are owned, operated or provided through third party websites, by Trip Merchant Ltd. (&ldquo;<strong>Trip Merchant</strong>&rdquo;).</p>
<p>&nbsp;</p>
<p>In this Privacy Policy, &ldquo;<strong>Personal Information</strong>&rdquo; means information about an identifiable individual. &nbsp;&ldquo;<strong>We</strong>&rdquo; means (and &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;, and &ldquo;<strong>ours</strong>&rdquo; refer to) Trip Merchant. &nbsp;&ldquo;<strong>You</strong>&rdquo; means (and &ldquo;<strong>your</strong>&rdquo; refers to) the user of the Site. &nbsp;&ldquo;<strong>Membership-Based Organization and Employer</strong>&rdquo; means an organization which partners with us to provide travel experiences to its employees and/or members, who are referred to in this Privacy Policy as &ldquo;<strong>Customers</strong>&ldquo;. &nbsp;The organizations who offer travel experiences and other services, and with whom we connect these Customers through our digital marketing platform, are called &ldquo;<strong>Suppliers</strong>&ldquo;. &nbsp;Our activities which we conduct in order to accomplish this are called our &ldquo;<strong>Services</strong>&ldquo;.</p>
<p>&nbsp;</p>
<p>IT IS IMPORTANT THAT YOU READ ALL OF THE TERMS AND CONDITIONS CAREFULLY.</p>
<p>&nbsp;</p>
<ol>
    <li><strong>Acceptance of Terms</strong></li>
</ol>
<p>&nbsp;</p>
<p>By accessing and continuing to use the Site, and by providing certain Personal Information to Trip Merchant through the Site, you are indicating your acceptance to be bound by the terms and conditions of this Privacy Policy. If you do not accept these terms and conditions, please do not access or use the Site, or provide Personal Information through the Site.</p>
<p>&nbsp;</p>
<ol start="2">
    <li><strong>Modifications and Notices</strong></li>
</ol>
<p>&nbsp;</p>
<p>We may revise this Privacy Policy, at any time, by updating this posting. &nbsp;We may provide notice to you of material revisions by means of a general notice on the Site. &nbsp;Your continued use of the Site after such revisions are posted, will signify your agreement to these revised terms. &nbsp;Therefore, you should visit this page periodically to review this Privacy Policy.</p>
<p>&nbsp;</p>
<ol start="3">
    <li><strong>Trip Merchant Respects Your Privacy</strong></li>
</ol>
<p>&nbsp;</p>
<p>Trip Merchant is committed to protecting the privacy of all of our users of the Site and maintaining the integrity of any Personal Information that we collect, use, retain or disclose via the Site. The purpose of this Privacy Policy is to inform you about how and why we collect Personal Information on the Site, to outline the steps we take to safeguard your Personal Information, and to provide you with Trip Merchant contact information if you have any questions or concerns about our privacy practices.</p>
<p>&nbsp;</p>
<ol start="4">
    <li><strong>General Information</strong></li>
</ol>
<p>&nbsp;</p>
<p>Our Internet servers may passively and automatically collect certain information about website visitors&rsquo; traffic patterns, which may be linked to their Internet Protocol (IP) addresses (which are unique Internet &ldquo;addresses&rdquo; assigned to all Internet users by their Internet Service Providers). &nbsp;Server logs may record statistical information, such as visitors&rsquo; IP addresses, type of operating systems, time and duration of visit, pages requested, and identify categories of visitors by items such as domains and browser types. &nbsp;These statistics are generally collected and used on an aggregate basis.</p>
<ul>
    <li><strong><em>Cookies:&nbsp;</em></strong>The Site may use &ldquo;cookies&rdquo;. &nbsp;Cookies are small text files offered to your computer by servers in order to keep track of your browser as you navigate a website. &nbsp;Cookies may be stored on your hard drive, or in temporary (cache) memory, in which case they are deleted when you shut down your browser or turn off your computer. &nbsp;We may use cookies to record session information, such as your browsing habits and past activity, to enable us to provide you with improved services such as customized webpage content. &nbsp;You can disable cookies using your Internet browser&rsquo;s settings. &nbsp;Please consult your browser&rsquo;s help function for information about how to disable cookies. &nbsp;Note that if you disable cookies, certain features of the Site may not function properly.</li>
    <li><strong><em>Links to Third Party Websites:&nbsp;</em></strong>The Site may contain links to other websites that are provided as a convenience only, and which may have different privacy policies and practices than we do. &nbsp;We have no responsibility for these third party websites, and you are advised to review the privacy policies of any third party websites you choose to visit.</li>
</ul>
<p>&nbsp;</p>
<ol start="5">
    <li><strong>What Personal Information do we Collect, and how do we Collect it?</strong></li>
</ol>
<p>&nbsp;</p>
<p>In the course of operating the Site, we have collected and will collect Personal Information from you, including:</p>
<ul>
    <li>information you voluntarily submit directly to us when you register a username or use the Site to request information from Suppliers about travel experiences. User profile information you submit to us may include your name, email address and travel preferences. &nbsp;In the case of a Membership-Based Organization and Employer, we may ask for information about your organization and individuals who are affiliated with organization, so that we can better customize the Customer&rsquo;s experience using the Services, and so that we can continue to interact with your organization. &nbsp;In the same manner and for the same purposes, at the sign-up stage and as needed from time to time, we request contact information for individuals who represent Suppliers;</li>
    <li>information you voluntarily submit to us, when you post, transmit or submit messages, opinions or other content, including, but not limited to, text, photographs, images, illustrations, graphics or multimedia clips and any other user generated content and whether related to corporate content (in the case of Membership-Based Organizations and Employers and Suppliers) or testimonials and travel experiences (in the case of Customers) (collectively, &ldquo;<strong>Submitted Materials</strong>&ldquo;), to or through the Site (whether directly or indirectly, through third party websites and applications), and to interact with Trip Merchant and other users within, or in connection with, the Site;</li>
    <li>information you voluntarily submit to us, if you use the Services to coordinate group bookings or to purchase travel experiences directly through the Services under our Trip Merchant Journeys division; and</li>
    <li>information we may passively and automatically collect from you, such as your IP address, and other information about website visitors&rsquo; traffic patterns, as discussed above.</li>
</ul>
<p>In some cases, your Personal Information may be submitted to us indirectly, from a Membership-Based Organization and Employer who believes you may be interested in travel experiences which are offered by Suppliers (and information about which is available on or through the Site). &nbsp; Your Personal Information may also be submitted to us through a third party service provider, such as Facebook Connect, Twitter, Google+ and Instagram. &nbsp;In other cases, you may be asked to provide information to a third party, such as when you submit requests for travel information to Suppliers.</p>
<p>We collect your Personal Information when you sign up for a username and a password, when you request information about travel experiences, when you update information about your representatives (in the case of Suppliers and Membership-Based Organizations and Employers), when you provide Submitted Materials to us and any other time when you choose to provide us your Personal Information. &nbsp;If you do not want to provide your Personal Information to us, then you should not use the Site to learn about travel experiences, or to provide Submitted Materials.</p>
<p>We collect this Personal Information for the following purposes:</p>
<ul>
    <li>to offer Services to you and others through the Site, including to allow Customers to indicate preferences and to request information regarding their desired travel experiences, and to allow Membership-Based Organizations and Employers and Suppliers to offer information about travel experiences to Customers. We also collect information about Customers, so that Suppliers can provide them information about travel experiences and other offers which Suppliers believe may of interest to Customers;</li>
    <li>to facilitate the sharing of information between Customers and Suppliers or the destination management company and/or inbound operator partners (collectively, our &ldquo;<strong>Trip Merchant Journeys Partners</strong>&ldquo;) we work with, to allow you to purchase Trip Merchant Journeys experiences, when Customers indicate their wish to obtain information from Suppliers about travel experiences, and when Suppliers respond to those requests, or when Customers purchase Trip Merchant Journeys experiences;</li>
    <li>for billing purposes and the tracking of sales of Services from Suppliers to Customers;</li>
    <li>to advertise, develop, enhance and provide Services to viewers and users of the Site, including sharing Submitted Materials to other users of the Site;</li>
    <li>to protect users of the Site and Trip Merchant against error and fraud; and</li>
    <li>to carry out any other purpose that you have authorized or that is required or permitted by law.</li>
</ul>
<p>&nbsp;</p>
<p>Further reference in this Privacy Policy to &ldquo;<strong>Identified Purposes</strong>&rdquo; means the reasons set out in this&nbsp;<strong>Section 5</strong>.</p>
<p>&nbsp;</p>
<ol start="6">
    <li><strong>What about Children?</strong></li>
</ol>
<p>&nbsp;</p>
<p>People under the age of 18 are not permitted to use the Site. &nbsp;Parents and legal guardians are responsible for ensuring their children do not use the Site.</p>
<p>&nbsp;</p>
<ol start="7">
    <li><strong>Does Trip Merchant disclose your Personal Information to Others?</strong></li>
</ol>
<p>&nbsp;</p>
<p>Trip Merchant does&nbsp;<u>not</u> disclose Personal Information to third parties for any other purposes other than those for which it was collected and which have been identified to you. &nbsp;For example, Personal Information may be disclosed in the following circumstances:</p>
<ul>
    <li>by publishing details about Membership-Based Organizations and Employers and Suppliers, so that Customers can obtain information about travel experiences;</li>
    <li>by sharing contact information and travel experience requests and preferences of Customers with Suppliers and Trip Merchant Journeys Partners, so that Suppliers and Trip Merchant Journeys Partners can respond to these requests and provide you information about other travel experiences that Suppliers or Trip Merchant Journeys Partners believe might be of interest to Customers. &nbsp;We may also share this information with Membership-Based Organizations and Employers, so that we and they can better understand how Customers affiliated with their organization are using the Site. &nbsp;IF YOU DO NOT WANT SUPPLIERS, MEMBERSHIP-BASED ORGANIZATIONS AND EMPLOYERS OR TRIP MERCHANT JOURNEYS PARTNERS TO VIEW AND USE YOUR PERSONAL INFORMATION IN THIS MATTER, DO NOT ENTER THAT PERSONAL INFORMATION INTO YOUR PROFILE OR OTHERWISE SUBMIT IT TO THE SITE;</li>
    <li>by publishing testimonials about the Services and the particular member experiences of Customers, so that we can better market the services. IF YOU DO NOT WANT USERS OF THE SITE TO VIEW YOUR PERSONAL INFORMATION IN THIS MATTER, DO NOT SUBMIT IT TO THE SITE; and</li>
    <li>by collecting and sharing with Membership-Based Organizations and Employers and Suppliers information related to a Customer&rsquo;s purchase of Services, for the purposes of calculating our entitlement to fees that are payable by the Supplier.</li>
</ul>
<p>If you submit your billing payment information to a third party, such as a Supplier, the information you submit in this regard is between you and the Supplier and subject to any terms which might be imposed on you by a Supplier. &nbsp;If Trip Merchant collects your billing payment information, we will do so through methods other than the Site (for example, over the telephone).</p>
<p>Other disclosures of Personal Information may occur in order to fulfil the Identified Purposes which are set out in&nbsp;<strong>Section 5</strong> above. &nbsp;If we plan to use Personal Information for a different purpose, we will do so with your consent, or as required or permitted by law.</p>
<p>There are a variety of circumstances where we may need to share Personal Information with third parties. For example, Trip Merchant may transfer Personal Information to a third party engaged by us to perform functions on our behalf (such as storing data).</p>
<p>In situations where we disclose Personal Information to third parties in accordance with this Privacy Policy, we do not disclose more information than is required and, where it is reasonable and practical to do so, we enter privacy agreements with third parties to whom we disclose Personal Information.</p>
<p>&nbsp;</p>
<ol start="8">
    <li><strong>Transfer of Personal Information to Service Providers outside Canada</strong></li>
</ol>
<p>&nbsp;</p>
<p>We will only use Personal Information for the purposes that we identify to you. &nbsp;We may engage service providers to assist us with fulfilling these purposes, and, in some instances, these service providers may be located outside Canada.</p>
<p>We only select service providers that protect Personal Information in a manner that is comparable to the protection we provide under our own privacy policies. &nbsp;However, Personal Information may be subject to, and accessed under, the laws of the countries in which our service providers operate.</p>
<p>If you have any questions about our transfer of Personal Information to our service providers outside Canada, or if you would like to learn more about our privacy policies in that regard, please contact our Privacy Officer at the contact information provided below.</p>
<p>&nbsp;</p>
<ol start="9">
    <li><strong>How does Trip Merchant</strong> <strong>safeguard your Personal Information?</strong></li>
</ol>
<p>&nbsp;</p>
<p>We protect Personal Information against such risks as loss, theft, unauthorized access, disclosure, copying, use, modification or destruction, through generally accepted security measures. &nbsp;Only authorized employees and agents have access to Personal Information. Trip Merchant employees who have access to Personal Information receive training regarding privacy protection. All computers on which Personal Information is stored, are password protected. Trip Merchant uses data encryption to increase the security of the Personal Information you provide to us via the Site. &nbsp;The security of your Personal Information is further clarified in and subject to the Trip Merchant Terms of Service.</p>
<p>While we take commercially reasonable measures to maintain a secure site, electronic communications and databases are subject to errors, tampering and break-ins, and we cannot guarantee or warrant that such events will not take place and we will not be liable to you for any such occurrences.</p>
<p>&nbsp;</p>
<ol start="10">
    <li><strong>How long will we Retain your Personal Information?</strong></li>
</ol>
<p>&nbsp;</p>
<p>We will keep your Personal Information for as long as is necessary for us to fulfill the Identified Purposes. &nbsp;Generally speaking, that means we will destroy or anonymize your Personal Information when your relationship with us is no longer active. &nbsp;Despite this, in limited circumstances we may be required by law to keep your Personal Information for a longer period of time.</p>
<p>&nbsp;</p>
<ol start="11">
    <li><strong>Your Consent/Verifying and Amending Personal Information</strong></li>
</ol>
<p>&nbsp;</p>
<p>You may withdraw your consent for Trip Merchant to manage your Personal Information in a particular way, subject to legal or contractual restrictions and reasonable notice. &nbsp;However, this may limit our ability to provide you with certain services and/or products. You may contact Trip Merchant for more information regarding the implications of withdrawing consent. &nbsp;AS THE INTERNET IS A PUBLIC, DYNAMIC MEDIUM, PLEASE BE ADVISED THAT YOU CANNOT &ldquo;UN-SHARE&rdquo; CONTENT YOU HAVE ALREADY SENT TO OR SHARED WITH OTHER USERS OF THE SITE, INCLUDING SUPPLIERS AND TRIP MERCHANT JOURNEYS PARTNERS.</p>
<p>You can also access and review your Personal Information held by Trip Merchant and make appropriate changes to it, by logging in with your login credentials. &nbsp;If necessary, you may also contact us. You may be required to put your request in writing and show us identification. The reason we ask for identification is to ensure that, among other things, we do not unintentionally release your Personal Information to a third party who is representing him/herself as you.</p>
<p>&nbsp;</p>
<p>Trip Merchant can be reached at:</p>
<p>Trip Merchant Ltd.</p>
<p>Attention: &nbsp;The Privacy Officer</p>
<p>info@tripmerchant.com</p>
<p>You may update or request access to your Personal Information by writing or emailing Trip Merchant at the addresses provided above.</p>
<p>&nbsp;</p>
<ol start="12">
    <li><strong>Language</strong></li>
</ol>
<p>&nbsp;</p>
<p>You and Trip Merchant have requested and agree that this Privacy Policy and all documents related to this Privacy Policy be drawn up in English. Les parties ont demand&eacute; que cette convention ainsi que tous les documents que s&rsquo;y rattachent soient r&eacute;dig&eacute;s en anglais (version fran&ccedil;aise).</p>
<p>&nbsp;</p>
<ol start="13">
    <li><strong>Resolving Your Concerns</strong></li>
</ol>
<p>&nbsp;</p>
<p>If you would like more information about Trip Merchant&rsquo;s privacy practices, please contact the Trip Merchant Privacy Officer. Please let us know about your questions or concerns and we will do our best to help you.</p>
<p>&nbsp;</p>
<p><strong><em>Last revised&nbsp;</em></strong><strong><em>February 8, 2019</em></strong></p>`;

const PrivacyPolicy = () => {
    const [data, setData] = useState();

    useEffect(() => {
        getData("/pages/17").then((res) => {
          setData(res?.data);
        });
      }, []);
  return (
    <>
      <Title title={data?.attributes?.Title || "Trip Merchant | Privacy Policy"} />
      <HeroSection
        heading={"TRIP MERCHANT"}
        title={"WEBSITE PRIVACY POLICY"}
        image={require("../../assets/images/whoweare.jpg")}
        bannerClass={"justify-content-center text-center"}
        //Tag={"h4"}
        color="#F25926"
      />
      <div className="container my-3">
        <h3 className="text-center mt-2">{data?.attributes?.blooks[0]?.Cards[0]?.Title || "WEBSITE PRIVACY POLICY"}</h3>
        <div
        //   dangerouslySetInnerHTML={{
        //     __html: policy,
        //   }}
        />
          <ReactMarkdown>{data?.attributes?.blooks[0]?.Cards[0]?.detail || "Loading..."}</ReactMarkdown>
    
      </div>
    </>
  );
};

export default PrivacyPolicy;
