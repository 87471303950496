export const strapiUrlApi = "https://cms.tripmerchant.com/api";
export const strapiUrl = "https://cms.tripmerchant.com";
// export const strapiUrlApi = "http://localhost:1337/api";
// export const strapiUrl = "http://localhost:1337";
export const getData = async (url) => {
  const response = await fetch(`${strapiUrlApi}${url}?populate=deep`);
  const data = await response.json();
  return data;
};

export function expandArrayWithPreviousValues(arr, targetLength) {
  const currentLength = arr.length;

  if (currentLength < targetLength) {
    const expandedArray = [...arr];

    for (let i = currentLength; i < targetLength; i++) {
      expandedArray.push(arr[i % currentLength]);
    }

    return expandedArray;
  }

  return arr;
}
